<template>
  <div class="mine">
    <mt-header :title="$t('mine')">
      <!-- <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link> -->
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="bar">
        <img src="../assets/logo.png" width="86px">
        <div class="name">{{ user.name }}</div>
        <div class="phone">{{ user.phone }}</div>
      </div>
      <div class="list">
        <!-- <div class="item" @click="$router.push('/mine/personalInfo')">
          <div class="logo">
            <img src="../assets/personal.png" height="16px">
          </div>
          <div class="value">{{ $t('personalInfo') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div> -->
        <div class="item" @click="$router.push('/mine/repaymentInfo')">
          <div class="logo">
            <img src="../assets/repayment.png" height="16px">
          </div>
          <div class="value">{{ $t('repaymentInfo') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="$router.push('/mine/orderHistory')">
          <div class="logo">
            <img src="../assets/loanRecord.png" height="16px">
          </div>
          <div class="value">{{ $t('orderHistory') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="$router.push('/mine/bankCard')">
          <div class="logo">
            <img src="../assets/bindBankCard.png" width="20px">
          </div>
          <div class="value">{{ $t('bankCard') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="$router.push('/mine/repaymentRecord')">
          <div class="logo">
            <img src="../assets/loanRecord.png" height="16px">
          </div>
          <div class="value">{{ $t('repaymentRecord') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="$router.push('/mine/refund')">
          <div class="logo">
            <img src="../assets/refund.png" width="20px">
          </div>
          <div class="value">{{ $t('refund') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <!-- <div class="item" @click="$router.push('/mine/inviteFriends')">
          <div class="logo">
            <img src="../assets/personal.png" height="16px">
          </div>
          <div class="value">{{ $t('inviteFriends') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div> -->
        <!-- <div class="item" @click="$router.push('/mine/discountCoupon')">
          <div class="logo">
            <img src="../assets/coupon.png" height="16px">
          </div>
          <div class="value">{{ $t('discountCoupon') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="$router.push('/mine/myPromotion')">
          <div class="logo">
            <img src="../assets/inviteFriends.png" height="16px">
          </div>
          <div class="value">{{ $t('myPromotion') }}</div>
          <img class="arrow" src="../assets/arrow.png">
        </div> -->
      </div>
    </div>
    <div class="bottom">
      <mt-button type="danger" size="large" @click="onLogOut">{{ $t('logOut') }}</mt-button>
    </div>
    <my-tabbar value="account" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { mapMutations } from "vuex";
import MyTabbar from '../components/myTabbar.vue';
import { getUserInfo } from '../utils/api'
export default {
  name: 'Mine',
  components: {
    callPhone,
    MyTabbar,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(["SET_ACCESS_TOKEN", "SET_USER"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
      ])
      this.$indicator.close();
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    onLogOut() {
      this.SET_ACCESS_TOKEN();
      this.SET_USER({});
      this.$router.push('/user/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  .content {
    .bar {
      min-height: 175px;
      background-image: linear-gradient(to right, #47B526 , #5cd239);
      text-align: center;
      color: white;
      img {
        margin-top: 20px;
      }
      .name {
        font-size: 14px;
        margin: 5px;
      }
      .phone {
        font-size: 13px;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #535353;
        height: 37px;
        border-bottom: 1px solid #DBDADA;
        .logo {
          margin-left: 30px;
          width: 50px;
          display: flex;
          align-items: center;
        }
        .value {
          flex: 1;
        }
        .arrow {
          width: 9px;
          margin-right: 26px;
        }
      }
    }
  }
  .bottom {
    padding: 40px 20px;
  }
}
</style>
